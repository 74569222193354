












































import { Component, Prop, Vue } from "vue-property-decorator";
import { ValidationObserver } from "vee-validate";
import { DefaBaseButton, DefaTransitionFadeOutIn } from "@defa-as/components";
import PasswordInput from "@/components/inputs/PasswordInput.vue";
import PasswordConfirmInput from "@/components/inputs/PasswordConfirmInput.vue";
import { loadingModule } from "@/store/modules/loading-module";
import { userModule } from "@/store/modules/user-module";
import { ROUTE_NAMES } from "@/router";

@Component({
  components: {
    PasswordConfirmInput,
    PasswordInput,
    DefaBaseButton,
    DefaTransitionFadeOutIn,
    ValidationObserver,
  },
})
export default class PasswordRecoveryPasswordForm extends Vue {
  @Prop({ required: true }) readonly hash!: string;
  model = {
    password: "",
    passwordConfirm: "",
  };
  success = false;

  get loadingModule() {
    return loadingModule;
  }

  get loginRoute() {
    return {
      name: ROUTE_NAMES.LOGIN,
    };
  }

  async resetPassword() {
    await userModule.resetPassword({
      hash: this.hash,
      newPassword: this.model.password,
    });
    this.success = true;
  }
}
