











import { Component, Prop, Vue } from "vue-property-decorator";
import { DefaConfirmation } from "@defa-as/components";
import { httpClient } from "@/http";
import PasswordRecoveryPasswordForm from "@/components/password-recovery/PasswordRecoveryPasswordForm.vue";
import { ROUTE_NAMES } from "@/router";

@Component({
  components: {
    PasswordRecoveryPasswordForm,
    DefaConfirmation,
  },
})
export default class ViewPasswordRecoveryConfirmation extends Vue {
  @Prop({ required: true }) readonly hash!: string;

  get loginRoute() {
    return {
      name: ROUTE_NAMES.LOGIN,
    };
  }

  get confirmationUrl() {
    return `/users/reset/confirm-hash/${this.hash}`;
  }

  get httpClient() {
    return httpClient;
  }
}
