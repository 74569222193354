import { render, staticRenderFns } from "./ViewPasswordRecoveryConfirmation.vue?vue&type=template&id=628f16d6&scoped=true&"
import script from "./ViewPasswordRecoveryConfirmation.vue?vue&type=script&lang=ts&"
export * from "./ViewPasswordRecoveryConfirmation.vue?vue&type=script&lang=ts&"
import style0 from "./ViewPasswordRecoveryConfirmation.vue?vue&type=style&index=0&id=628f16d6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "628f16d6",
  null
  
)

export default component.exports